
import { useStore } from "vuex";
import { defineComponent, computed, watch, reactive } from "@vue/runtime-core";
import {
  IUseBaseAppLayout,
  useBaseAppLayout
} from "@/hooks/layout/useBaseAppLayout";
import { ITableViewConfiguration } from "@/components/Table/ts/interfaces/common";
import PageMainHeading from "@/components/Layout/PageMainHeading.vue";
import CategoryTable from "@/components/Table/ts/instances/categories/CategoriesTable.vue";

import AppButton from "@/components/ui/Buttons/AppButton.vue";
import BtnContent from "@/components/ui/Buttons/BtnContent.vue";
import { categoriesSetup } from "@/views/interfaces/categoriesInterfaces";
import DeleteBrandDialog from "@/components/ui/Modal/Dialog/instances/DeleteBrandDialog.vue";

import CategoryModal from "@/components/ui/Modal/Dialog/instances/CategoryModal.vue";
import { ICategories } from "@/api/interfaces/categories/ICategories";
import { useUserTable } from "@/hooks/tables/modules/usersTable/usersTable";
import AppTableController from "@/components/Table/ts/AppTableController.vue";
import { useFaq } from "@/hooks/faq/useFaq";
import { sortOrders } from "@/enums/main/sortOrders";

export default defineComponent({
  name: "Categories",
  components: {
    DeleteBrandDialog,
    CategoryModal,
    BtnContent,
    AppButton,
    PageMainHeading,
    CategoryTable,
    AppTableController
  },

  setup(): categoriesSetup {
    const store = useStore();

    const viewConfiguration: ITableViewConfiguration = reactive({
      page: 1,
      perPage: 10
    });
    const options = {
      viewConfiguration,
      selectedTab: {
        id: null
      }
    };

    const {
      requestCategoriesHandler,
      changeViewConfigurationHandler,
      addEditCategory,
      removeCategory
    } = useFaq(options);
    // Page layout refs
    const layoutRefs: IUseBaseAppLayout = useBaseAppLayout({
      initialSearchQuery: "",
      onFiltersChange: requestCategoriesHandler,
      onChangeSearchQuery: () => {
        changeViewConfigurationHandler({
          page: 1,
          perPage: viewConfiguration.perPage
        });
      }
    });

    watch(viewConfiguration, () => requestCategoriesHandler());

    requestCategoriesHandler();

    return {
      ...layoutRefs,
      ...useUserTable(),
      viewConfiguration,
      categoryList: computed(() => store.getters.categoriesList),
      totalAccountsCount: computed(() => store.getters.totalCategories),
      requestCategoriesHandler,
      changeViewConfigurationHandler,
      addEditCategory,
      removeCategory
    };
  },

  data() {
    return {
      editText: "",
      editId: 0,
      isLoading: false,
      createCategoryOpened: false,
      sortConfig: {
        byName: { order: sortOrders.asc, keyName: "title" }
      },
      deleteCategoryOpened: false,
      editData: {} as ICategories | undefined
    };
  },

  computed: {
    emptyText(): string {
      this.requestCategoriesHandler?.();

      return `No categories found!`;
    }
  },

  methods: {
    async removingCategory(cId: number): Promise<void> {
      this.editId = cId;
      this.editData = this.categoryList.find(
        categoryItem => categoryItem.id == cId
      );
      this.deleteCategoryOpened = true;
    },

    cleanData(): void {
      this.editData = {} as ICategories | undefined;
      this.deleteCategoryOpened = false;
      this.createCategoryOpened = false;
    },

    async editingCategory(cId: number) {
      this.editId = cId;
      this.createCategoryOpened = true;

      this.editData = this.categoryList.find(
        categoryItem => categoryItem.id == cId
      );
    },

    async actionWithCategory(dataCategory: ICategories) {
      this.isLoading = true;
      let success = false;
      if (dataCategory.id) {
        success = await this.addEditCategory(dataCategory, "patch");
      } else {
        success = await this.addEditCategory(dataCategory, "post");
      }
      if (success) {
        this.createCategoryOpened = false;
        this.cleanData();
        this.requestCategoriesHandler?.();
      }
      this.isLoading = false;
    },

    async onDeleteConfirm(): Promise<void> {
      this.isLoading = true;
      const success = await this.removeCategory(this.editData?.id);
      if (success) {
        this.deleteCategoryOpened = false;
        this.cleanData();
        this.requestCategoriesHandler?.();
      }
      this.isLoading = false;
    }
  }
});
